import {AfterViewChecked, Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {first, Observable} from 'rxjs';
import {FinalReport} from '../../+state/-rt-medical-convo-analyser.types';
import {Store} from '@ngrx/store';
import {selectFinalReport} from '../../+state/-rt-medical-convo-analyser.selectors';
import {environment} from '../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

import {setFinalReport} from '../../+state/-rt-medical-convo-analyser.actions';
import * as _ from 'lodash';
import { ClinicalFindingsComponent } from '../clinical-findings/clinical-findings.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';

@Component({
    selector: 'hippocraticum-ai-frontend-report',
    standalone: true,
    imports: [ClinicalFindingsComponent, NgIf, NgForOf, AsyncPipe],
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements AfterViewChecked {
    @ViewChildren('autoGrowTextarea') textareas!: QueryList<ElementRef<HTMLTextAreaElement>>;

    $finalReport: Observable<FinalReport | undefined>
    finalReport: FinalReport | undefined

    constructor(private store: Store, private http: HttpClient) {
        this.$finalReport = this.store.select(selectFinalReport)

        this.$finalReport.subscribe(report => {
            this.finalReport = report
        })
    }

    ngAfterViewChecked(): void {
        this.textareas.forEach(textareaRef => {
            this.adjustHeight(textareaRef.nativeElement);
        });
    }

    updateField(path: string, value: Event, keyToUpdate?: string, isBulletPoint = false): void {
        if (!this.finalReport) {
            return;
        }

        const target = value.target as HTMLInputElement;
        let valueToUpdate = target.value;

        if (isBulletPoint) {
            valueToUpdate = valueToUpdate.replace(/^•\s/, ""); // Remove the bullet point and following whitespace
        }

        const updatedFinalReport = _.cloneDeep(this.finalReport); // clone the current report

        // Set the new value based on whether keyToUpdate is provided
        if (keyToUpdate) {
            _.set(updatedFinalReport, path + '.' + keyToUpdate, valueToUpdate);
        } else {
            _.set(updatedFinalReport, path, valueToUpdate);
        }

        this.store.dispatch(setFinalReport({ finalReport: updatedFinalReport })); // assuming you have an update action for the report
    }


    generateMedicalNote() {
        this.$finalReport.pipe(first()).subscribe((finalReport) => {

            if (!finalReport) {
                return;
            }

            this.http.post(environment.baseApiUrl + "/generate_medical_note", finalReport.medical_note, {responseType: 'blob'}).subscribe((response) => {
                    const blob = new Blob([response], {type: 'application/pdf'});
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = 'medical-note.pdf';
                    link.click();
                },
                (error) => {
                    console.error(error);
                });
        });
    }


    adjustHeight(target: EventTarget | null): void {
        const textarea = target as HTMLTextAreaElement;

        if (textarea) {
            // Reset textarea height to 'auto' to get the real scrollHeight
            textarea.style.height = 'auto';

            // Set the textarea's height to its scrollHeight
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

}
