import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';

@Component({
  selector: 'hippocraticum-ai-frontend-sign-in',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatError,
  ],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss',
})
export class SignInComponent {
  isError = false;
  isForgotPassword = false;
  isSentEmail = false;
  sentEmail = '';
  signInForm: FormGroup;
  forgotPasswordForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      remember: [false],
    });
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  forgotPassword(): void {
    this.isForgotPassword = !this.isForgotPassword;
    this.forgotPasswordForm.reset();
  }

  onSubmit(): void {
    if (this.signInForm.valid) {
      console.log(this.signInForm.value);
      // Add your login logic here
    }
  }

  sendEmail(): void {
    if (this.forgotPasswordForm.valid) {
      console.log(this.forgotPasswordForm.value);
      this.sentEmail = this.forgotPasswordForm.value.email;
      this.isSentEmail = true;
      // Add your login logic here
    }
  }
}
