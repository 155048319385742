import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Transcription} from '../../+state/-rt-medical-convo-analyser.types';
import {Store} from '@ngrx/store';
import {selectTranscription,} from '../../+state/-rt-medical-convo-analyser.selectors';
import {FeedItemV2} from './feedItem.type.tts';
import { IdleModalComponent } from '../idle-modal/idle-modal.component';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { FeedMessageComponent } from '../feed-message/feed-message.component';
import { FeedSysMessageComponent } from '../feed-sys-message/feed-sys-message.component';


@Component({
    selector: 'hippocraticum-ai-frontend-feed',
    standalone: true,
    imports: [
        IdleModalComponent,
        FeedMessageComponent,
        FeedSysMessageComponent,
        NgSwitch,
        NgSwitchCase,
        NgIf,
        NgForOf
    ],
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnDestroy {
    feedItems: FeedItemV2[] = [];
    $transcription!: Observable<Transcription[]>;

    private subscriptions: Subscription[] = [];

    constructor(
        private store: Store,
        private cdr: ChangeDetectorRef,
    ) {
        this.initializeObservables();
        this.subscribeToTranscription();
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private initializeObservables() {
        this.$transcription = this.store.select(selectTranscription);
    }

    private subscribeToTranscription() {
        const transcriptionSubscription = this.$transcription.subscribe(transcriptions => {
            this.processTranscriptions(transcriptions);
            if (transcriptions.length > 0) {
                this.scrollToBottom();
                this.cdr.detectChanges();
            }
        });
        this.subscriptions.push(transcriptionSubscription);
    }

    private processTranscriptions(transcriptions: Transcription[]) {
        const updatedFeedItems: FeedItemV2[] = [];
        let last_speaker = ""

        transcriptions.forEach(transcription => {
            if (last_speaker === transcription.speaker) {
                const lastFeedItem = updatedFeedItems[updatedFeedItems.length - 1];
                lastFeedItem.sentences.push(transcription);
            } else {
                updatedFeedItems.push({
                    sequence_id: updatedFeedItems.length,
                    type: 'feedMessage',
                    sentences: [transcription],
                    speaker: transcription.speaker,
                    completed: transcription.is_complete
                });

                last_speaker = transcription.speaker
            }
        });
        this.feedItems = updatedFeedItems;
        this.scrollToBottom();
    }

    public isUserValueValid(value: string): boolean {
        return value !== 'None' && value !== 'doctor' && value === 'patient';
    }

    private scrollToBottom() {
        setTimeout(() => {
            const element = document.getElementById("scrollable");
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        }, 50);
    }
}


// TODO IMPLEMENT TIME STAMP TIME IN FEED BUBBLE
// TODO IMPLEMENT NEW ENDPOINT FOR FINAL TRANSCRIPTION
