import { inject, Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {AngularFirestore, DocumentSnapshot} from '@angular/fire/compat/firestore';
import {FinalReport} from '../+state/-rt-medical-convo-analyser.types';
import { DocumentReference } from '@firebase/firestore-types';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private cookieService = inject(CookieService);
  private firestore = inject(AngularFirestore);

  setUserIdentifier(): string {
    const userIdentifier = this.generateUserIdentifier();
    this.cookieService.set('HippocraticumAiUserIdentifier', userIdentifier);
    return userIdentifier;
  }

  getSessionReference(): DocumentReference<any> {
    let userId =  this.cookieService.get('HippocraticumAiUserIdentifier');

    if (!userId) {
      userId = this.setUserIdentifier();
    }

    return this.firestore.collection("sessions").doc(userId).ref;
  }

  storeConsultationSession(session: FinalReport) {
    const sessionDocumentRef = this.getSessionReference();
    const sessionUID = this.createHumanReadableUID(session);
    const sessionData = { [sessionUID]: session };
    return sessionDocumentRef.set(sessionData, { merge: true });
  }

  private getAllSessions(): Promise<DocumentSnapshot<[FinalReport]>> {
    const sessionDocumentRef = this.getSessionReference();
    return sessionDocumentRef.get() as any
  }

  private createHumanReadableUID(finalReport: FinalReport): string {
    const patientName = finalReport.medical_note.patient_information.full_name.replace(/\s/g, '-');
    const consultationDateTime = finalReport.medical_note.patient_information.consultation_datetime.replace(/[^a-zA-Z0-9]/g, '-');
    const uid = `${patientName}_${consultationDateTime}`;
    return uid.replace(/[^a-zA-Z0-9-_]/g, '');
  }


  private generateUserIdentifier(): string {
    return crypto.randomUUID()
  }
}
