import { Route } from '@angular/router';
import { AppComponent } from './app/app.component';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { RtMedicalConvoAnalyserEffects } from './+state/-rt-medical-convo-analyser.effects';
import { RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY, rtMedicalConvoAnalyserReducer } from './+state/-rt-medical-convo-analyser.reducer';

export const featureRoutes: Route[] = [
    {
        path: '',
        component: AppComponent,
        providers: [
            provideState(RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY, rtMedicalConvoAnalyserReducer),
            provideEffects(RtMedicalConvoAnalyserEffects),
        ],
    },
];
