import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'hippocraticum-ai-frontend-token-verify',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './token-verify.component.html',
  styleUrl: './token-verify.component.scss',
})
export class TokenVerifyComponent {
  @Output() codeVerified = new EventEmitter<string>();
  code: string = '';
  digit1: string = '';
  digit2: string = '';
  digit3: string = '';
  digit4: string = '';
  digit5: string = '';
  digit6: string = '';

  constructor(private elementRef: ElementRef,) {}

  onInput(event: Event, nextInput: number = 1): void {
    const input = event.target as HTMLInputElement;
    if (/^\d+$/.test(input.value)) {
      const maxLength = parseInt(input.getAttribute('maxlength') || '1');
      const currentLength = input.value.length;

      if (currentLength >= maxLength && nextInput !== 7) {
        const nextElement = this.elementRef.nativeElement.querySelector(
          `input[name="digit${nextInput}"]`
        ) as HTMLInputElement;
        if (nextElement) {
          nextElement.focus();
        }
      }
    } else {
      input.value = '';
    }
    this.code = this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6;
    this.codeVerified.emit(this.code);
    if (nextInput === 7 && this.code.length === 6) {
      return;
    }
  }
}
