import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {Observable, Subscription, take} from 'rxjs';
import {CurrentView} from '../../+state/-rt-medical-convo-analyser.types';
import {Store} from '@ngrx/store';
import {
  clearState,
  closedSession,
  pauseSession, resumeSession,
  setCurrentView,
  startSession, stopSession,
} from '../../+state/-rt-medical-convo-analyser.actions';
import {StopwatchService} from '../../services/stopwatch.service';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import {selectStreamOnline, selectCurrentView} from '../../+state/-rt-medical-convo-analyser.selectors';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'hippocraticum-ai-frontend-bottom-action-wrapper',
  standalone: true,
  templateUrl: './bottom-action-wrapper.component.html',
  imports: [
    NgIf,
    AsyncPipe,
    LottieComponent
  ],
  styleUrls: ['./bottom-action-wrapper.component.scss']
})
export class BottomActionWrapperComponent implements OnDestroy {
  CurrentView = CurrentView
  formattedTime = '0:00';
  time = 0;
  timerSubscription: Subscription;

  public $currentView: Observable<CurrentView>
  _currentView!: CurrentView;
  public $steamOnline: Observable<boolean>

  options: AnimationOptions = {
    path: 'assets/animations/loading.json',
    loop: true,
  };

  constructor(
      private store: Store,
      private stopwatchService: StopwatchService,
      private cdr: ChangeDetectorRef
      ) {
    this.$currentView = this.store.select(selectCurrentView)
    this.$steamOnline = this.store.select(selectStreamOnline)

    this.$currentView.subscribe(currentView => {
        this._currentView = currentView
    });

    this.timerSubscription = this.stopwatchService.time$.subscribe(time => {
      this.time = time;
      this.formattedTime = this.formatTime();
      this.cdr.detectChanges();
    });

    this.$steamOnline.subscribe((stream_online) => {
      if (stream_online) {
        this.start()
      } else {
        this.pause()
      }
    })
  }

  toggleRecording() {
    this.$currentView.pipe(take(1)).subscribe((currentView) => {
      switch (currentView) {
        case CurrentView.SessionInit:
          this.startRecording();
          break;
        case CurrentView.SessionInProgress:
          this.pauseRecording();
          break;
        case CurrentView.SessionPaused:
          this.resumeRecording();
          break;
        case CurrentView.SessionEnd:
          this.startRecording();
          break;
        default:
          console.warn('Unknown currentView:', currentView);
      }
    });
  }

  startRecording() {
    this.updateSession(CurrentView.SessionInProgress);
    this.store.dispatch(startSession());
  }

  pauseRecording() {
    this.updateSession(CurrentView.SessionPaused);
    this.store.dispatch(pauseSession());
  }

  resumeRecording() {
    this.updateSession(CurrentView.SessionInProgress);
    this.store.dispatch(resumeSession());
  }

  stopRecording() {
    if (this._currentView === CurrentView.SessionError){
      this.store.dispatch(clearState())
      return
    }

    this.updateSession(CurrentView.SessionEnd);
    this.store.dispatch(stopSession());
    this.stop();
  }
  private updateSession(view: CurrentView) {
    this.store.dispatch(setCurrentView({ currentView: view }));
  }

  formatTime(): string {
    const min = Math.floor(this.time / 60);
    const sec = this.time % 60;
    return `${min}:${sec < 10 ? '0' : ''}${sec}`;
  }

  private start() {
    this.stopwatchService.start();
  }

  private pause() {
    this.stopwatchService.pause();
  }

  private stop() {
    this.stopwatchService.stop();
  }

  isSessionNewPausedEndOrRunning(currentView: CurrentView | null): boolean {
    if (currentView === null) return false;
    return [CurrentView.SessionInit, CurrentView.SessionPaused, CurrentView.SessionEnd, CurrentView.SessionInProgress, CurrentView.SessionError].includes(currentView);
  }

  isSessionNewPausedOrEnd(currentView: CurrentView | null): boolean {
    if (currentView === null) return false;
    return [CurrentView.SessionInit, CurrentView.SessionPaused, CurrentView.SessionEnd].includes(currentView);
  }

  isSessionInProgress(currentView: CurrentView | null): boolean {
    return currentView === CurrentView.SessionInProgress;
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
  }
}
