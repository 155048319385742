import {Component, HostListener} from '@angular/core';
import {SessionService} from '../../services/session.service';
import {PreviousSessions} from './previous-sessions.type';
import {Store} from '@ngrx/store';
import * as RtMedicalConvoAnalyserActions from '../../+state/-rt-medical-convo-analyser.actions';
import {togglePreviousSessions} from '../../+state/-rt-medical-convo-analyser.actions';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'hippocraticum-ai-frontend-previous-sessions',
  standalone: true,
  templateUrl: './previous-sessions.component.html',
  styleUrls: ['./previous-sessions.component.scss'],
    imports: [
        NgIf,
        NgForOf
    ]
})
export class PreviousSessionsComponent {
    isMobile!: boolean;
    previousSessions: PreviousSessions[] = [];

    constructor(private sessionService: SessionService, private store: Store) {
        this.checkScreenSize(window.innerWidth)

        const previousSessions = this.sessionService.getSessionReference().get()

        previousSessions.then((doc) => {
            if (doc.exists) {
                const data = doc.data();
                this.previousSessions = Object.keys(data).map((key) => {
                    return {
                        id: key,
                        ...data[key]
                    }
                });
                console.log(this.previousSessions);
            }
        });
    }

    ViewSession(sessionId: string) {
        const previousSession = this.previousSessions.find((session) => session.id === sessionId);

        if (!previousSession) {
            return;
        }

        const {id, ...finalReport } = previousSession;

        this.store.dispatch(RtMedicalConvoAnalyserActions.openFinalReport({finalReport}))

    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.checkScreenSize((event.target as Window).innerWidth);
    }

    private checkScreenSize(width: number) {
        this.isMobile = width <= 900;
    }

    public togglePreviousSessions(): void {
        this.store.dispatch(togglePreviousSessions());
    }
}
