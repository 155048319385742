import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatError } from '@angular/material/form-field';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { TokenVerifyComponent } from '../token-verify/token-verify.component';

@Component({
  selector: 'hippocraticum-ai-frontend-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatError,
    TokenVerifyComponent,
  ],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss',
})
export class SignUpComponent {
  signUpForm: FormGroup;
  isSentEmail = false;
  isVerifiedEmail = false;
  sentEmail = '';
  passwordValidations = {
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSymbol: false,
    isValidLength: false,
  };
  symbols = [ "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "-", "+", "=", "{", "}", "[", "]", "|", "\\", ":", ";", "'", "\"", "<", ">", ",", ".", "?", "/", "~", "€", "£", "¥", "₹", "₣", "₤", "é", "à", "è", "ô", "ü", "ç", "ñ", "ø", "≈", "≠", "÷", "×", " ", "\t"];
  code: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router
  ) {
    this.signUpForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(15),
            this.passwordValidator.bind(this),
          ],
        ],
        repeatPassword: ['', [Validators.required]],
        remember: [false],
      },
      { validators: [this.passwordMatchValidator] }
    );
  }

  goToLogin(): void {
    this.router.navigate(['/sign-in']);
  }

  get isRepeatPasswordInvalid(): boolean {
    return (
      (this.signUpForm.get('repeatPassword')?.hasError('mismatch') &&
        this.signUpForm.get('repeatPassword')?.touched) ||
      false
    );
  }

  onSubmit(): void {
    if (this.signUpForm.valid) {
      console.log(this.signUpForm.value);
      this.isSentEmail = true;
      this.sentEmail = this.signUpForm.value.email;
      // Add your login logic here
    }
  }

  verifyEmail(): void {
    if (this.code.length === 6) {
      console.log(this.code);
      this.isVerifiedEmail = true;
    }
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      this.passwordValidations = {
        hasLowerCase: false,
        hasUpperCase: false,
        hasNumber: false,
        hasSymbol: false,
        isValidLength: false,
      };
      return null;
    }

    this.passwordValidations.hasLowerCase = /[a-z]/.test(value);
    this.passwordValidations.hasUpperCase = /[A-Z]/.test(value);
    this.passwordValidations.hasNumber = /[0-9]/.test(value);
    this.passwordValidations.hasSymbol = this.symbols.some(symbol => value.includes(symbol));
    this.passwordValidations.isValidLength =
      value.length >= 6 && value.length <= 15;

    return null;
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('repeatPassword')?.value;
    if (control.get('repeatPassword')) {
      control
        .get('repeatPassword')
        ?.setErrors(password === confirmPassword ? null : { mismatch: true });
    }
    return password === confirmPassword ? null : { mismatch: true };
  }
}
