import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { selectCurrentView } from '../../+state/-rt-medical-convo-analyser.selectors';
import { CurrentView } from '../../+state/-rt-medical-convo-analyser.types';
import { Store } from '@ngrx/store';
import { AsyncPipe, NgIf } from '@angular/common';
import { WelcomeModalComponent } from '../../components/welcome-modal/welcome-modal.component';
import { IdleModalComponent } from '../../components/idle-modal/idle-modal.component';
import { FeedComponent } from '../../components/feed/feed.component';
import { ErrorComponent } from '../../pages/error/error.component';

@Component({
  selector: 'hippocraticum-ai-frontend-content',
  standalone: true,
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  imports: [
    AsyncPipe,
    WelcomeModalComponent,
    IdleModalComponent,
    FeedComponent,
    ErrorComponent,
    NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentComponent {
  CurrentView = CurrentView;
  public $currentView: Observable<CurrentView>;

  constructor(private store: Store) {
    this.$currentView = this.store.select(selectCurrentView);
  }

  isSessionInProgressPausedOrEnd(currentView: CurrentView | null): boolean {
    return currentView === CurrentView.SessionInProgress ||
        currentView === CurrentView.SessionPaused ||
        currentView === CurrentView.SessionEnd;
  }
}
