import {Component, Input} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'hippocraticum-ai-frontend-idle-modal',
  standalone: true,
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss'],
  imports: [
    NgIf,
    NgClass
  ]
})
export class IdleModalComponent {
  @Input() grayTheme = false
}
