import {Component, Input} from '@angular/core';
 import {animate, state, style, transition, trigger} from '@angular/animations';
import {Transcription} from '../../+state/-rt-medical-convo-analyser.types';
import { NgForOf, NgIf } from '@angular/common';

@Component({
    selector: 'hippocraticum-ai-frontend-feed-message',
    standalone: true,
    templateUrl: './feed-message.component.html',
    styleUrls: ['./feed-message.component.scss'],
    imports: [
        NgIf,
        NgForOf
    ],
    animations: [
        trigger('fadeIn', [
            state('fadeInState', style({ opacity: 1 })),
            state('default', style({ opacity: 1 })),
            transition('void => fadeInState', [
                style({ opacity: 0 }),
                animate('500ms cubic-bezier(0.895, 0.030, 0.685, 0.220)')
            ])
        ])
    ]

})
export class FeedMessageComponent {
    @Input() message!: string;
    @Input() isUser!: boolean;
    @Input() finalReportView = false;
    @Input() transcriptions!: Transcription[]

    time!: string;

    constructor() {
        this.getCurrentTime();
    }

    getCurrentTime() {
        const currentDate = new Date();
        const formattedTime = currentDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
        this.time = `${formattedTime}`;
    }
}

// TODO: refactor all the components to work with angular 18
