import {ChangeDetectorRef, Component, Renderer2, ElementRef, AfterViewInit, NgZone, OnInit} from '@angular/core';
import {first, Observable} from 'rxjs';
import {FinalReport, MedicalNote} from '../../+state/-rt-medical-convo-analyser.types';
import {Store} from '@ngrx/store';
import {selectFinalReport, selectMedicalNote} from '../../+state/-rt-medical-convo-analyser.selectors';
import * as RtMedicalConvoAnalyserActions from '../../+state/-rt-medical-convo-analyser.actions';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { CurrentTab } from './final-report-current-tab.type';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../../environments/environment';
import { AsyncPipe, NgIf } from '@angular/common';
import { ReportComponent } from '../report/report.component';
import { FeedMessageComponent } from '../../components/feed-message/feed-message.component';

@Component({
    selector: 'hippocraticum-ai-frontend-final-report-view',
    standalone: true,
    templateUrl: './final-report-view.component.html',
    styleUrls: ['./final-report-view.component.scss'],
    imports: [
        AsyncPipe,
        NgIf,
        ReportComponent,
        FeedMessageComponent,
        LottieComponent
    ]
})
export class FinalReportViewComponent implements AfterViewInit, OnInit {
    CurrentTab = CurrentTab;
    currentTab: CurrentTab = CurrentTab.Loading;
    $finalReport: Observable<FinalReport | undefined>
    $medicalNote: Observable<MedicalNote | undefined>;

    options: AnimationOptions = {
        path: 'assets/animations/logo.json',
        loop: true,
    };

    constructor(
        private store: Store,
        private renderer: Renderer2,
        private el: ElementRef,
        private cdr: ChangeDetectorRef,
        private http: HttpClient,
        private ngZone: NgZone
    ) {
        this.$finalReport = this.store.select(selectFinalReport)
        this.$medicalNote = this.store.select(selectMedicalNote)
    }

    ngOnInit() {
        this.$finalReport.subscribe((finalReport) => {
            if (finalReport) {
                this.ngZone.run(() => {
                    this.currentTab = CurrentTab.Report;
                    this.cdr.detectChanges();
                });
            }
        });
    }


    // animate opening 
    ngAfterViewInit() {
            const wrapperElement = document.getElementById('final-notes-wrapper');
        setTimeout(() => {
            this.renderer.addClass(wrapperElement , 'show');
        }, 100);
    }


    onTabClick(tab: CurrentTab) {
        this.currentTab = tab;
    }

    exitSession() {
        // animate closing
        const wrapperElement = document.getElementById('final-notes-wrapper');
        this.renderer.removeClass(wrapperElement, 'show');
        setTimeout(() => {
            this.store.dispatch(RtMedicalConvoAnalyserActions.closeSession())
        }, 400);
        
    }

    processUserValue(value: string): boolean {
        if (value === 'None') {
            return false;
        }

        if (value === 'doctor'){
            return false;
        }

        return value === 'patient';
    }

    // TODO: create backend routes class
    generateMedicalNote() {
        this.$finalReport.pipe(first()).subscribe((finalReport) => {

            if (!finalReport) {
                return;
            }

            this.http.post( environment.baseApiUrl + "/generate_medical_note", finalReport.medical_note, {responseType: 'blob'}).subscribe((response) => {
                    const blob = new Blob([response], {type: 'application/pdf'});
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = 'medical-note.pdf';
                    link.click();
                },
                (error) => {
                    console.error(error);
                });
        });
    }
}
