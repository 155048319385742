import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectError} from '../../+state/-rt-medical-convo-analyser.selectors';

@Component({
    selector: 'hippocraticum-ai-frontend-error',
    standalone: true,
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
    errorTitle = 'Oops... something went wrong';
    errorMessage = 'We are working to get things up and ready!';

    constructor(private store: Store) {

        const $error = this.store.select(selectError)

        $error.subscribe(error => {
            if (error) {
                if (error.errorTitle) {
                    this.errorTitle = error.errorTitle
                }
                if (error.errorMessage) {
                    this.errorMessage = error.errorMessage
                }
            }
        })
    }

}
