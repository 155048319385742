import {Component, ChangeDetectionStrategy} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {CurrentView} from '../../+state/-rt-medical-convo-analyser.types';
import {selectCurrentView} from '../../+state/-rt-medical-convo-analyser.selectors';
import * as RtMedicalConvoAnalyserActions from '../../+state/-rt-medical-convo-analyser.actions';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
    selector: 'hippocraticum-ai-frontend-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
  imports: [
    AsyncPipe,
    NgIf
  ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
    public readonly $currentView: Observable<CurrentView>;
    public readonly CurrentView = CurrentView;

    constructor(private readonly store: Store<any>) {
        this.$currentView = this.store.select(selectCurrentView);
    }

    public toggleClinicalNotes(): void {
        this.store.dispatch(RtMedicalConvoAnalyserActions.toggleClinicalNotes());
    }

    public togglePreviousSessions(): void {
        console.log('togglePreviousSessions');
        this.store.dispatch(RtMedicalConvoAnalyserActions.togglePreviousSessions());
    }
}


// TODO: CLOSE SESSION NOT WORKING
