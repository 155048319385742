<div class="authentication-wrapper">
  <div class="auth-form">
    <div class="card header-card">
      <div class="icon-24 logo-24"></div>
      <div class="icon-24 en-icon"></div>
    </div>
    <div class="card card-form" *ngIf="!isSentEmail">
      <div class="scroll-container">
        <div class="title">Sign Up</div>
        <div class="description">Revolutionary HealthTec Solutions - Hippocraticum AI</div>
        <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()" class="mt-50">

          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" placeholder="@hippocraticum.ai" formControlName="email" required>
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" id="password" formControlName="password" required placeholder="Specify ...">
          </div>

          <div class="password-validations-list">
            <div class="validation-item" [class.success]="passwordValidations.hasLowerCase">
              <div class="status"></div>
              <div class="text">Small letters a-z</div>
            </div>
            <div class="validation-item" [class.success]="passwordValidations.hasUpperCase">
              <div class="status"></div>
              <div class="text">Capital letters A-Z</div>
            </div>
            <div class="validation-item" [class.success]="passwordValidations.hasNumber">
              <div class="status"></div>
              <div class="text">Numbers 0-9</div>
            </div>
            <div class="validation-item" [class.success]="passwordValidations.hasSymbol">
              <div class="status"></div>
              <div class="text">{{ 'Symbols @#$*+' }}</div>
            </div>
            <div class="validation-item" [class.success]="passwordValidations.isValidLength">
              <div class="status"></div>
              <div class="text">Min 6 max 15 characters</div>
            </div>
          </div>

          <div class="form-group" [class.with-error]="isRepeatPasswordInvalid">
            <label for="repeat_password">Repeat Password</label>
            <input type="password" id="repeat_password" formControlName="repeatPassword" required placeholder="Specify ...">
            <mat-error *ngIf="isRepeatPasswordInvalid" class="form-error">
              Passwords do not match
            </mat-error>
          </div>

          <mat-checkbox class="checkbox mt-20" formControlName="remember">Remember Login</mat-checkbox>

          <div class="form-actions mt-40">
            <button mat-raised-button type="submit" class="red-btn custom-btn">Sign Up</button>
          </div>
        </form>
      </div>
    </div>

    <div class="card card-form" *ngIf="isSentEmail && !isVerifiedEmail">
      <div class="scroll-container">
        <div class="title">Verify Email Address</div>
        <div class="sent-email-description">We sent you a verification token to</div>
        <div class="sent-email">{{ sentEmail }}</div>
        <div class="please-check-text">Please check your email!</div>

        <hippocraticum-ai-frontend-token-verify (codeVerified)="code = $event"></hippocraticum-ai-frontend-token-verify>

        <div class="form-actions mt-40">
          <button type="button" mat-raised-button class="red-btn custom-btn" (click)="verifyEmail()">Verify Email</button>
          <button type="button" mat-raised-button class="grey-btn custom-btn" (click)="isSentEmail = false">Cancel</button>
        </div>
      </div>
    </div>

    <div class="card card-form" *ngIf="isVerifiedEmail">
      <div class="scroll-container">
        <div class="title">Registration Successful!</div>
        <div class="forgot-description">Your account is pending approval from the administrator. We’ll provide you with an update as soon as your account gets activated.</div>

        <div class="form-actions mt-40">
          <button type="button" mat-raised-button class="grey-btn custom-btn" (click)="goToLogin()">Back to login</button>
        </div>
      </div>
    </div>
  </div>
  <div class="icon-wrapper mobile-laptop-icon">
  </div>
</div>
