<div class="authentication-wrapper">
  <div class="auth-form">
    <div class="card header-card">
      <div class="icon-24 logo-24"></div>
      <div class="icon-24 en-icon"></div>
    </div>
    <div class="card card-form" *ngIf="!isForgotPassword">
      <div class="scroll-container">
        <div class="title">Sign In</div>
        <div class="description">Revolutionary HealthTec Solutions - Hippocraticum AI</div>
        <form [formGroup]="signInForm" (ngSubmit)="onSubmit()" class="mt-50">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" placeholder="@hippocraticum.ai" formControlName="email" required>
          </div>
          <div class="form-group" [class.with-error]="isError">
            <label for="password">Password</label>
            <input type="password" id="password" formControlName="password" required placeholder="Specify ...">
            <mat-error *ngIf="isError" class="form-error">Error name</mat-error>
          </div>

          <mat-checkbox class="checkbox mt-20" formControlName="remember">Remember Login</mat-checkbox>

          <div class="form-actions mt-40">
            <button mat-raised-button type="submit" class="red-btn custom-btn">Sign In</button>
            <button type="button" mat-raised-button class="grey-btn custom-btn" (click)="forgotPassword()">Forgot password?</button>
          </div>
        </form>
      </div>
    </div>

    <div class="card card-form" *ngIf="isForgotPassword && !isSentEmail">
      <div class="scroll-container">
        <div class="title">Forgot password?</div>
        <div class="forgot-description">Enter your email, we will send new password to you</div>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="sendEmail()" class="mt-50">
          <div class="form-group">
            <label for="forgotEmail">Email</label>
            <input type="email" id="forgotEmail" placeholder="@hippocraticum.ai" formControlName="email" required>
          </div>
          <div class="form-actions mt-40">
            <button mat-raised-button type="submit" class="red-btn custom-btn">Reset password</button>
            <button type="button" mat-raised-button class="grey-btn custom-btn" (click)="isForgotPassword = false">Cancel</button>
          </div>
        </form>
      </div>
    </div>

    <div class="card card-form" *ngIf="isSentEmail">
      <div class="scroll-container">
        <div class="title">Success!</div>
        <div class="sent-email-description">We sent you a new password to</div>
        <div class="sent-email">{{ sentEmail }}</div>
        <div class="please-check-text">Please check your email!</div>
        <div class="form-actions mt-40">
          <button type="button" mat-raised-button class="grey-btn custom-btn" (click)="isForgotPassword = false; isSentEmail = false">Back to login</button>
        </div>
      </div>
    </div>
  </div>
  <div class="icon-wrapper">
  </div>
</div>
