<div class="segment" *ngIf="finalReport.medical_note.subjective.chief_complaints">
    <p>Clinical findings:</p>
    <div *ngFor="let item of finalReport.medical_note.objective.clinical_findings; let i = index">
        <input
          [value]="item.icd10_code"
          (blur)="updateField('medical_note.objective.clinical_findings.' + i, $event, 'icd10_code')"
        >
        <input
          [value]="item.description"
         (blur)="updateField('medical_note.objective.clinical_findings.' + i, $event, 'description')"
        >
    </div>
</div>
