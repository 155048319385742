import {Component, Renderer2, ElementRef, AfterViewInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectCurrentView} from '../../+state/-rt-medical-convo-analyser.selectors';
import {setCurrentView} from '../../+state/-rt-medical-convo-analyser.actions';
import {Observable} from 'rxjs';
import {CurrentView} from '../../+state/-rt-medical-convo-analyser.types';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'hippocraticum-ai-frontend-welcome-modal',
  standalone: true,
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent implements AfterViewInit{

  private $currentView: Observable<CurrentView>
  public version = environment.version

  constructor(private store: Store, private renderer: Renderer2, private el: ElementRef) {
    this.$currentView = this.store.select(selectCurrentView)
  }

  ngAfterViewInit() {
    const popupElement = document.getElementById('popup');
    setTimeout(() => {
      this.renderer.addClass(popupElement , 'show');
    }, 200);
  }

  startSession() {
    this.store.dispatch(setCurrentView({currentView: CurrentView.SessionInit}))
  }

}
