export const environment = {
  baseApiUrl: 'http://localhost:8000',
  baseWsUrl: 'ws://localhost:8000/ws/transcribe/',
  version: "0.3.0 - local",

  firebase: {
    apiKey: "AIzaSyB9vOL0yqERes5MXrgHDskwWdI7M4C1yDo",
    authDomain: "hippocraticum-ai-30540.firebaseapp.com",
    projectId: "hippocraticum-ai-30540",
    storageBucket: "hippocraticum-ai-30540.appspot.com",
    messagingSenderId: "884162655240",
    appId: "1:884162655240:web:384d5bab38d72e4da1cdbd"
  }
};
