import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentView } from '../../+state/-rt-medical-convo-analyser.selectors';
import { CurrentView } from '../../+state/-rt-medical-convo-analyser.types';
import { BottomActionWrapperComponent } from '../../components/bottom-action-wrapper/bottom-action-wrapper.component';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'hippocraticum-ai-frontend-footer',
  standalone: true,
  imports: [
    BottomActionWrapperComponent,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  CurrentView = CurrentView;
  public $currentView: Observable<CurrentView>;

  constructor(private store: Store) {
    this.$currentView = this.store.select(selectCurrentView);
  }

  ifNotCurrentViewStartOrEnd(currentView: CurrentView | null): boolean {
    return currentView !== null && currentView !== CurrentView.Start && currentView !== CurrentView.SessionEnd;
  }
}
