import { Route } from '@angular/router';
import { ShellComponent } from './container/shell/shell.component';
import {
  SignInComponent
} from '../../../../libs/common/components/src/lib/authentication-components/sign-in/sign-in.component';
import {
  SignUpComponent
} from '../../../../libs/common/components/src/lib/authentication-components/sign-up/sign-up.component';

export const appRoutes: Route[] = [
    {
      path: 'sign-in',
      component: SignInComponent,
    },
    {
      path: 'sign-up',
      component: SignUpComponent,
    },
    {
        path: "**",
        redirectTo: "sign-in"
    }
    //{
    //    path: '',
    //    component: ShellComponent,
    //    children: [
    //        {
    //          path: 'consultation-demo',
    //          loadChildren: () => import('@hippocraticum-ai-frontend/consultation-demo').then(m => m.featureRoutes)
    //        },
    //        {
    //            path: "**",
    //            redirectTo: "consultation-demo"
    //        }
    //        ]
    //}
];
